import { Plugin, Template } from 'ckeditor5';

// This plugin will grab the desired height from the editor's config and apply that style to the editor element
class HeightPlugin extends Plugin {
  init() {
    const height = this.editor.config.get('height');
    if (height) {
      this.editor.ui.view.editable.extendTemplate(new Template({
        attributes: {
          style: {
            minHeight: `${height}px`,
          }
        }
      }));
    }
  }
}

export default HeightPlugin;
