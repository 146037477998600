import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preview", "input", "embedCode", "copyLink", "copiedText"];

  connect() {
    const modalId = this.element.dataset.modalId;
    const radioGroupName = `preview_type_${modalId}`;
    const selectedRadio = this.element.querySelector(`input[name="${radioGroupName}"]:checked`);
    const defaultButton = this.element.querySelector(`input[name="${radioGroupName}"][value="button"]`);

    if (!selectedRadio && defaultButton) {
      defaultButton.checked = true;
    }

    this.updatePreview();
  }

  updatePreview() {
    const modalId = this.element.dataset.modalId;
    const radioGroupName = `preview_type_${modalId}`;
    const buttonType = this.element.querySelector(`input[name="${radioGroupName}"]:checked`)?.value;
    const text = this.inputTarget.value.trim();
    const appUrl = this.element.dataset.appUrl;
    const scriptUrl = 'https://resources.unlockhealthnow.com/embed-script/embed.js';
    const previewSection = this.previewTarget.closest(".preview-section");
    const style = buttonType === 'button' ? ' style="border-radius: 6px;background-color: #337ab7;color: white;padding: 10px 16px;text-decoration: none;"' : '';

    this.previewTarget.innerHTML = `<a href="javascript:void(0)" class="readonly"${style}>${text}</a>`;
    this.embedCodeTarget.innerText = `<script src="${scriptUrl}"></script>\n<a class="hra-link" target="_blank" href="${appUrl}"${style}>${text}</a>`;

    if (text === "") {
      previewSection.classList.add("hidden");
    } else {
      previewSection.classList.remove("hidden");
    }
  }

  async copyToClipboard(event) {
    event.preventDefault();
    const codeText = this.embedCodeTarget.innerText.trim();
    await navigator.clipboard.writeText(codeText);

    this.copyLinkTarget.classList.add("hidden");
    this.copiedTextTarget.classList.remove("hidden");

    setTimeout(() => {
      this.copyLinkTarget.classList.remove("hidden");
      this.copiedTextTarget.classList.add("hidden");
    }, 1000);
  }
}
