import { Controller } from '@hotwired/stimulus';
import { alert } from '../../helpers/jquery_wrapper';
import { jsonPost } from '../../helpers/fetch_utils';

export default class extends Controller {
  static values = {
    reportPath: String,
    dismissNotificationPath: String,
    isCorporationDashboard: Boolean,
  };

  static targets = [
    'timePeriod',
    'active',
    'unlaunched',
    'suspended',
    'offline',
    'quickFind',
    'dashboardRow',
    'notificationAlert',
    'filterUpArrow',
    'filterDownArrow',
    'filterDrawer',
    'firstFilterRow',
    'applicationTypeFilter',
    'applicationFilter',
    'ctaFilter'
  ];

  connect() {
    this.toggleCtaOptions();
  }

  getFilters() {
    return {
      active: this.activeTarget.checked,
      unlaunched: this.unlaunchedTarget.checked,
      suspended: this.suspendedTarget.checked,
      offline: this.offlineTarget.checked,
      quickFind: this.quickFindTarget.value.toLowerCase().trim(),
    };
  }

  hraMeetsFilterCriteria(hra, filters) {
    const tagValue = hra.querySelector('.tag').innerText.toLowerCase().trim();
    const filterText = hra.dataset.filterText.toLowerCase().trim();
    const statuses = ['active', 'unlaunched', 'suspended', 'offline'];
    const checkedStatuses = statuses.filter((status) => filters[status]);

    if ((checkedStatuses.length === 0 || checkedStatuses.includes(tagValue)) && filterText.includes(filters.quickFind)) {
      return true;
    }

    return false;
  }

  runQuickFilter() {
    const filters = this.getFilters();

    this.dashboardRowTargets.forEach((hra) => {
      const hasLegacyApp = hra.dataset.hasLegacyApp === 'true';
      let legacyApp, legacyAppMatches;

      // Toggle the legacy app based on if it matches
      if (hasLegacyApp) {
        legacyApp = hra.nextElementSibling.nextElementSibling;
        legacyAppMatches = this.hraMeetsFilterCriteria(legacyApp, filters);

        this.showOrHideLegacyAppDrawer(legacyAppMatches, hra)
      }

      // If either the app or the legacy app matches, show the app
      if (this.hraMeetsFilterCriteria(hra, filters) || legacyAppMatches) {
        hra.classList.remove('hide');
      } else {
        hra.classList.add('hide');
      }
    });
  }

  async generateReport() {
    const data = {
      criteria: {
        time_period: this.timePeriodTarget.value,
        is_ajax: true,
      },
    };

    const json = await jsonPost(this.reportPathValue, data);
    this.setReportData(json);
  }

  setReportData(json) {
    json.rows.forEach((row) => {
      const template = document.createElement('template');
      template.innerHTML = row.content;
      this.dashboardRowTargets.filter((t) => t.dataset.rowId).find((t) => t.dataset.rowId.toString() === row.id.toString()).replaceWith(template.content.firstChild);
    });

    if (!this.isCorporationDashboardValue) {
      this.runQuickFilter();
    }
  }

  async dismissNotification(e) {
    const body = { user_notification_id: this.notificationAlertTarget.dataset.id };
    await jsonPost(this.dismissNotificationPathValue, body);
    alert(this.notificationAlertTarget, 'close');
  }

  sendReportToReporting(e) {
    e.preventDefault();
    const { url, authenticityToken } = e.target.dataset;
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    const criteria = ['startDate', 'endDate', 'applicationTypeId', 'applicationId'];

    criteria.forEach((criteriaStr) => {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      // Filter parameters need to be in snake case
      hiddenField.setAttribute('name', `criteria[${criteriaStr.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`)}]`);
      hiddenField.setAttribute('value', e.target.dataset[criteriaStr]);
      form.appendChild(hiddenField);
    });

    const authenticityTokenField = document.createElement('input');
    authenticityTokenField.setAttribute('type', 'hidden');
    authenticityTokenField.setAttribute('name', 'authenticity_token');
    authenticityTokenField.setAttribute('value', authenticityToken);
    form.appendChild(authenticityTokenField);

    document.body.appendChild(form);
    form.submit();
  }

  toggleLegacyAppDrawer(e) {
    const upgradedApp = e.target.closest('.module');
    const show = upgradedApp.nextElementSibling.nextElementSibling.classList.contains('hide');

    this.showOrHideLegacyAppDrawer(show, upgradedApp);
  }

  showOrHideLegacyAppDrawer(show, upgradedApp) {
    const dropShadow = upgradedApp.nextElementSibling;
    const legacyApp = dropShadow.nextElementSibling;

    if (show) {
      legacyApp.classList.remove('hide');
      upgradedApp.querySelector('.down-arrow').classList.add('hide');
      upgradedApp.querySelector('.up-arrow').classList.remove('hide');
      upgradedApp.classList.add('no-margin');
      dropShadow.classList.remove('hide');
    } else {
      legacyApp.classList.add('hide');
      upgradedApp.querySelector('.down-arrow').classList.remove('hide');
      upgradedApp.querySelector('.up-arrow').classList.add('hide');
      upgradedApp.classList.remove('no-margin');
      dropShadow.classList.add('hide');
    }
  }

  toggleFilterDrawer() {
    if (this.filterUpArrowTarget.classList.contains('hide')) {
      this.firstFilterRowTarget.classList.add('small-margin');
      this.filterDrawerTarget.classList.remove('hide');
      this.filterDownArrowTarget.classList.add('hide');
      this.filterUpArrowTarget.classList.remove('hide');
    } else {
      this.firstFilterRowTarget.classList.remove('small-margin');
      this.filterDrawerTarget.classList.add('hide');
      this.filterDownArrowTarget.classList.remove('hide');
      this.filterUpArrowTarget.classList.add('hide');
    }
  }

  toggleCtaOptions() {
    if (!this.hasCtaFilterTarget) {
      return;
    }

    this.ctaFilterTarget.querySelectorAll('option').forEach((option) => this.toggleCtaOption(option));

    // Reset the filter if the cta is no longer visible and default to the first visible option
    const visibleCtaOptionIds = [...this.ctaFilterTarget.querySelectorAll('option:not(.hide)')].map((option) => option.value);

    if (!visibleCtaOptionIds.includes(this.ctaFilterTarget.value)) {
      this.ctaFilterTarget.value = this.ctaFilterTarget.querySelectorAll('option:not(.hide)')[0]?.value;
    }
  }

  toggleCtaOption(option) {
    const appId = parseInt(this.applicationFilterTarget.value);
    const appTypeId = parseInt(this.applicationTypeFilterTarget.value);

    // If there is an app id, show ctas that are in use for the app
    // Else show ctas available to the selected app type
    if (appId !== -1 && appId !== NaN) {
      if (JSON.parse(option.dataset.appIds).includes(appId)) {
        option.classList.remove('hide');
      } else {
        option.classList.add('hide');
      }
    } else {
      const appTypeIds = JSON.parse(option.dataset.appIds);

      // There will be none if it's available to all app types
      if (appTypeIds.length === 0 || appTypeIds.includes(appTypeId)) {
        option.classList.remove('hide');
      } else {
        option.classList.add('hide');
      }
    }
  }
}
