import { Controller } from '@hotwired/stimulus';
import DropzoneCreator from '../../dropzone/dropzone_creator';

export default class extends Controller {
  static values = {
    riskFactors: Array,
    additionalOptions: Array,
    dropzoneImages: Object,
    imageUploadPath: String,
  };

  static classes = ['hide'];

  static targets = [
    'display10YearPercent',
    'display30YearPercent',
    'tenYearPercentBox',
    'thirtyYearPercentBox',
    'exampleBox',
    'beFastGraphic',
    'beFastGraphics',
    'beFastGraphicRadios',
    'submit',
  ];

  connect() {
    this.toggleDefaultCustomFieldForRiskFactors(this.riskFactorsValue);

    if (!this.additionalOptionsValue.includes('custom_be_fast_graphic')) {
      this.toggleDefaultCustomFieldForAdditionalOptions(this.additionalOptionsValue);
    }

    if (this.hasBeFastGraphicRadiosTarget) {
      this.toggleBeFastGraphics();
      this.dzCreator = new DropzoneCreator('#report_customization_form', { listenToForm: false });

      const dzOpts = {
        acceptedFiles: 'image/png,image/jpeg',
        dictInvalidFileType: 'Image must be of type PNG or JPEG.',
        url: this.imageUploadPathValue,
      };
      Object.entries(this.dropzoneImagesValue).forEach(([name, attributes]) => this.createImageDropzone(name, attributes.url, { ...dzOpts, ...attributes.opts, paramName: name }, attributes.hidden_field_name));
    }
  }

  createImageDropzone(name, url, opts, hidden_field_name) {
    this.dzCreator.createInstance(`#${name}_dropzone`, `#customizations_healthaware_report_customization_form_${hidden_field_name}`, url, opts);
  }

  display10YearPercentTargetConnected(target) {
    this.triggerEvent('change', target);
  }

  display30YearPercentTargetConnected(target) {
    this.triggerEvent('change', target);
  }

  // Store CKEditor instances once they initialize so we can set values on them
  initializeCKEditor( { detail }) {
    this.ckEditorInstances ||= {};
    this.ckEditorInstances[detail.element.id] = detail;
  }

  submit(e) {
    if (this.hasBeFastGraphicRadiosTarget) {
      e.preventDefault();
      e.stopPropagation();

      this.submitTargets.forEach((s) => {
        s.value = s.dataset.disableWith;
        s.disabled = true;
      });

      this.dzCreator.processDzs();
    }
  }

  toggleBeFastGraphics(e) {
    const selected = this.beFastGraphicRadiosTargets.find((radio) => radio.checked).value;

    document.getElementById(selected).classList.remove(this.hideClass);

    this.beFastGraphicsTargets.forEach((element) => {
      element.classList.add(this.hideClass);
    });

    if (selected === 'default') {
      this.beFastGraphicTarget.classList.remove(this.hideClass);
      document.getElementById('custom_be_fast_graphic_dropzone').classList.add(this.hideClass);
    } else if (selected === 'custom') {
      this.beFastGraphicTarget.classList.add(this.hideClass);
      document.getElementById('custom_be_fast_graphic_dropzone').classList.remove(this.hideClass);
    } else {
      this.beFastGraphicTarget.classList.add(this.hideClass);
      document.getElementById('custom_be_fast_graphic_dropzone').classList.add(this.hideClass);
    }
  }

  toggleDefaultCustomField(e) {
    const radioValue = this.getCheckedRadioValue(e.currentTarget) === 'true';
    const textarea = e.currentTarget.nextSibling.querySelector('textarea');
    const ckInstance = this.ckEditorInstances[textarea.id];
    const editor = ckInstance.editor;
    if (radioValue) {
      e.currentTarget.nextSibling.classList.add('hide');
      if (editor && editor.getData() !== '') editor.setData('');
    } else {
      e.currentTarget.nextSibling.classList.remove('hide');
      ckInstance.repositionMaxCount();
      if (editor && editor.getData() === '') editor.setData(textarea.dataset.defaultValue);
    }
  }

  toggleDefaultCustomFieldForRiskFactors(arr) {
    arr.forEach((item) => {
      const elm = document.querySelector(`[data-field-name='${_.camelCase(item)}']`);
      const isDefaultSelected = this.getCheckedRadioValue(elm) === 'true';

      if (isDefaultSelected) {
        elm.nextSibling.classList.add('hide');
      }
    });
  }

  toggleDefaultCustomFieldForAdditionalOptions(arr) {
    arr.forEach((item) => {
      const elm = document.querySelector(`[data-field-name='${_.camelCase(item)}']`);
      if (elm) {
        const radioValue = this.getCheckedRadioValue(elm) === 'true';

        if (radioValue) {
          elm.nextSibling.classList.add('hide');
        }
      }
    });
  }

  toggleCvdRisk(e) {
    const radioValue = this.getCheckedRadioValue(e.currentTarget) !== 'true';
    const is10Year = e.currentTarget.dataset.fieldName.includes('10');

    if (radioValue) {
      if (is10Year) {
        this.tenYearPercentBoxTarget.classList.add('hide');
      } else {
        this.thirtyYearPercentBoxTarget.classList.add('hide');
      }
    } else if (is10Year) {
      this.tenYearPercentBoxTarget.classList.remove('hide');
    } else {
      this.thirtyYearPercentBoxTarget.classList.remove('hide');
    }

    if (!this.tenYearPercentBoxTarget.classList.contains('hide') || !this.thirtyYearPercentBoxTarget.classList.contains('hide')) {
      this.exampleBoxTarget.classList.remove('hide');
    } else {
      this.exampleBoxTarget.classList.add('hide');
    }
  }

  triggerEvent(eventName, element) {
    const event = new Event(eventName);
    element.dispatchEvent(event);
  }

  getCheckedRadioValue(element) {
    return [...element.querySelectorAll('input[type="radio"]')].find((i) => i.checked).value;
  }

  toggleConditionalVisibility(elem) {
    const target = elem.target;
    const value = target.value;
    const displayTarget = document.getElementById(target.dataset.displayTarget);

    this.showHide(displayTarget, value === 'true');
  }

  showHide(element, show) {
    if (show) {
      element.classList.remove('hide');
    } else {
      element.classList.add('hide');
    }
  }

  camelCaseRickFactor(str) {
    const words = str.split('_').map((word, index) => (index === 0
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()));

    return words.join('');
  }
}
