import { Controller } from '@hotwired/stimulus';
import {datepicker} from "../helpers/jquery_wrapper";

export default class extends Controller {
  static values = {
    initial: String,
  };

  static targets = [
    'input',
  ];

  connect() {
    this.setDatePicker();
  }

  setDatePicker() {
    datepicker('[data-behaviour~=datepicker]', {
      autoclose: true,
    });

    this.inputTarget.readOnly = true;

    if (this.initialValue) {
      datepicker(this.inputTarget.parentElement.id, 'update', this.initialValue);
      this.inputTarget.value = this.formatDate(new Date(this.initialValue));
    }
  }

  formatDate(date) {
    return (("0" + (date.getMonth() + 1)).slice(-2)) + '/' + ("0" + date.getDate()).slice(-2) + '/' + date.getFullYear();
  }
}
