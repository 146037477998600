import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    createClientUrlParam: String,
    newClientUrlParamPath: String,
    saveClientUrlParamPath: String,
    clientApplicationClientUrlParams: String,
    hraRootUrl: String,
    createUrlPath: String,
    archiveUrlPath: String,
    qrCodePath: String,
    saveQrCodePath: String,
  };

  static targets = [
    'clientUrlParamCategory',
    'clientUrlParamName',
    'clientUrlName',
    'campaign',
    'source',
    'medium',
    'content',
    'copied',
    'saveApplicationUrl',
  ];

  connect() {
    if (location.href.endsWith('urls/new') || location.href.endsWith('urls/new/')) {
      document.querySelector('#client-url').innerText = this.hraRootUrlValue;
      this.updateUrl();
    }
    $('form').on('keypress', (e) => { if (e.key == 'Enter') return false; });
  }

  saveApplicationUrl(e) {
    e.preventDefault();
    const campaign = $('#campaign select option:selected');
    const source = $('#source select option:selected');
    const medium = $('#medium select option:selected');
    const content = $('#content select option:selected');
    const data = {
      campaign_param_id: campaign.text() !== 'None' ? campaign.val() : undefined,
      source_param_id: source.text() !== 'None' ? source.val() : undefined,
      medium_param_id: medium.text() !== 'None' ? medium.val() : undefined,
      content_param_id: content.text() !== 'None' ? content.val() : undefined,
      url_name: this.clientUrlNameTarget.value,
    };
    $.ajax({
      url: this.createUrlPathValue,
      method: 'POST',
      data,
      success: (data) => window.location = location.href.replace('new', data.application_url_id),
      error: (response) => {
        document.querySelector('.application-urls-controller').innerHTML = response.responseText;
      },
    });
  }

  updateUrl() {
    const campaign = $('#campaign select option:selected').text();
    const source = $('#source select option:selected').text();
    const medium = $('#medium select option:selected').text();
    const content = $('#content select option:selected').text();
    const utms = {
      utm_campaign: campaign !== 'None' ? campaign : undefined,
      utm_source: source !== 'None' ? source : undefined,
      utm_medium: medium !== 'None' ? medium : undefined,
      utm_content: content !== 'None' ? content : undefined,
    };

    let newUrl = document.querySelector('#base_url input').value;
    if (newUrl.lastIndexOf('/') < 10 && newUrl[newUrl.length - 1] != '/') newUrl += '/';
    Object.entries(utms).forEach(([key, value]) => {
      if (value) {
        newUrl += newUrl.indexOf('?') === -1 ? '?' : '&';
        newUrl += `${key}=${value}`;
      }
    });
    document.querySelector('#client-url').innerText = newUrl;
  }

  copy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(document.querySelector('#client-url').innerText);
    this.copiedTarget.innerText = 'Copied URL to clipboard!';
    setTimeout(() => {
      this.copiedTarget.innerText = '';
    }, '3000');
  }

  copyUrl(e) {
    e.preventDefault();
    navigator.clipboard.writeText(e.params.url);
    e.target.innerText = 'Copied!';
    setTimeout(() => {
      e.target.innerText = 'Copy URL';
    }, '2000');
  }

  newClientUrlParam(e) {
    e.preventDefault();
    if (!$('#new_client_url_param_modal').html() == '') return;
    $.ajax({
      url: this.newClientUrlParamPathValue,
      method: 'GET',
      data: {
        category: e.target.innerText.replace('Add ', ''),
      },
      success: (data) => {
        $('#new_client_url_param_modal').html(data);
        $('#new_client_url_param').modal('toggle');
        MaxCount.init($('#max-count-input').data('maxcount', 45));
      },
    });
  }

  closeModal() {
    $('#new_client_url_param').modal('toggle');
    $('#new_client_url_param_modal').html('');
  }

  saveNewClientUrlParam(e) {
    if (e) e.preventDefault();
    const category = document.querySelector('#clientUrlParamCategory').value;
    const value = document.querySelector('#max-count-input').value;
    $.ajax({
      url: this.saveClientUrlParamPathValue,
      method: 'POST',
      data: {
        category,
        value,
      },
      success: () => {
        $('#new_client_url_param').modal('toggle');
        $('#new_client_url_param_modal').html('');
        this.fetchNewValues(category);
      },
      error: (response) => {
        $('#new_client_url_param_modal').html('');
        $('#new_client_url_param_modal').html(response.responseText);
        $('.modal-backdrop')[0].remove();
        $('#new_client_url_param').modal('toggle');
        MaxCount.init($('#max-count-input').data('maxcount', 45));
      },
    });
  }

  archiveUrl(e) {
    e.preventDefault();
    $.ajax({
      url: this.archiveUrlPathValue,
      method: 'DELETE',
      data: {
        application_url_id: e.params.id,
      },
      success: () => {
        window.location.reload();
      },
    });
  }

  fetchNewValues(category) {
    $.ajax({
      url: this.clientApplicationClientUrlParamsValue,
      method: 'GET',
      data: {
        category,
      },
      success: (data) => {
        const el = $(`#${category} select`);
        el.empty();
        data.options.forEach((item) => {
          el.append($('<option></option>')
            .attr('value', item[1]).text(item[0]));
        });
        el.val(data.options[data.options.length - 1][1]);
        this.updateUrl();
      },
      error: () => {
        location.reload;
      },
    });
  }

  getQrCode(path) {
    $.ajax({
      url: path || this.qrCodePathValue,
      method: 'GET',
      data: {
        url: document.querySelector('#client-url').innerText,
      },
      success: (data) => {
        document.querySelector('#qr-code').innerHTML = data.qr_code;
      },
    });
  }

  saveQrCode(e) {
    e.preventDefault();
    fetch(`${this.saveQrCodePathValue}?type=${e.params.type}&url=${document.querySelector('#client-url').innerText}`)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `qr-code.${e.params.type}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
