import { Controller } from '@hotwired/stimulus';
import { populateSelect } from "../../helpers/ajax_utils";
import { jsonPost} from "../../helpers/fetch_utils";

export default class extends Controller {
  static values = {
    getClientsPath: String,
    deleteHraDataPath: String,
  };

  static targets = [
    'corporationSelect',
    'clientSelect',
    'firstName',
    'lastName',
    'dob',
    'deleteResult',
    'deleteButton'
  ];

  connect() {
    this.updateClients();
  }

  updateClients() {
    populateSelect(
      this.clientSelectTarget,
      this.getClientsPathValue,
      { corporation_id: this.corporationSelectTarget.value }
    );
  }

  getValues() {
    return {
      corporation_id: this.corporationSelectTarget.value,
      client_id: this.clientSelectTarget.value,
      first_name: this.firstNameTarget.value,
      last_name: this.lastNameTarget.value,
      dob: this.dobTarget.value,
    }
  }

  deleteHraData() {
    this.deleteButtonTarget.disabled = true
    this.deleteButtonTarget.textContent = 'Please wait...'

    jsonPost(this.deleteHraDataPathValue, this.getValues()).then((result) => {
      if (result.success) {
        this.deleteResultTarget.textContent = `You have deleted ${result.numDeleted} records.`
      } else {
        this.deleteResultTarget.textContent = 'Internal Error'
      }

      this.deleteButtonTarget.disabled = false;
      this.deleteButtonTarget.textContent = 'Delete Record(s)';
      this.deleteResultTarget.classList.remove('hide');
    });
  }

  deleteDataConfirm() {
    const values = this.getValues();

    if (!values.corporation_id || !values.client_id || !values.first_name || !values.last_name || !values.dob) {
      alert("Please fill out all fields");
    } else {
      const confirmed = confirm("Are you sure you want to proceed? This action cannot be undone.");

      if (confirmed) {
        this.deleteHraData();
      }
    }
  }
}
