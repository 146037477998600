import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    addToModalPath: String,
    showModal: Boolean,
    displayAutosave: Boolean,
    orgName: String,
  };

  static targets = ['modalContainer', 'showModalButton', 'defaultCKEditorRadio', 'defaultTextInputRadio',
    'conditionalVisibilityControl', 'populateOrg', 'autosave', 'ckEditorWithDefault', 'variableIntro',
    'myselfSelectedCKE', 'pageIntroCKE', 'myselfSelectedRadio', 'myselfSelectedDefault', 'pageIntroRadio',
    'pageIntroDefault',
  ];

  connect() {
    this.ckEditorsInitialized = 0;

    // We can't hide the CKeditor boxes before they are done initializing, otherwise their height properties don't get properly set
    if (!this.pendingConditionalVisibilityTargets) {
      this.pendingConditionalVisibilityTargets = [];
    }

    if (this.showModalValue) this.showModalButtonTarget.click();
    if (this.displayAutosaveValue) this.autosaveTargets.forEach((t) => t.classList.remove('hide'));
  }

  pageIntroDefaultTargetConnected(elem) {
    this.oldIntroDefault = elem.dataset.altDefaultText;
  }

  defaultTextInputRadioTargetConnected(elem) {
    if (!elem.checked) return;
    this.toggleTextInput(elem);
  }

  conditionalVisibilityControlTargetConnected(elem) {
    if (!elem.checked) return;
    this.toggleConditionalVisibility(elem, true);
  }

  populateOrgTargetConnected(elem) {
    const text = elem.nextSibling.textContent;
    elem.nextSibling.textContent = text.replace('{ORGANIZATION_NAME}', this.orgNameValue);
  }

  populateModal(e) {
    e.preventDefault();
    const id = e.params.pageId;
    this.modalContainerTarget.innerHTML = '';
    const url = `${this.addToModalPathValue}?id=${id}`;

    fetch(url)
      .then((response) => { if (response.ok) return response.text(); })
      .then((html) => {
        this.modalContainerTarget.innerHTML = html;
        this.showModalButtonTarget.click();
      });
  }

  initializeCKEditor({ detail }) {
    if (this.ckEditorWithDefaultTargets.indexOf(detail.element) !== -1) {
      this.ckEditorInstances ||= {};
      const ckEditorFormGroup = detail.element.closest('.form-group');
      const ckId = ckEditorFormGroup.querySelector('textarea').id;
      this.ckEditorInstances[ckId] = detail;
      const radioElem = ckEditorFormGroup.previousElementSibling.querySelector('input:checked');
      this.toggleCKEditor(radioElem, ckEditorFormGroup);
    }

    this.ckEditorsInitialized += 1;

    // Once the last CKeditor is initialized, we can hide any targets that are waiting to be hidden
    const numEditors = document.querySelectorAll('[data-controller="ckeditor"]').length;
    if (this.ckEditorsInitialized === numEditors) {
      this.pendingConditionalVisibilityTargets.forEach(pendingDisplay => pendingDisplay.classList.add('hide'));
      this.pendingConditionalVisibilityTargets = [];
      this.ckEditorsInitialized = 0;
    }
  }

  toggleCKEditorHandler(e) {
    const ckEditorFormGroup = e.target.closest('.form-group').nextElementSibling;
    this.toggleCKEditor(e.target, ckEditorFormGroup);
  }

  getDefaultIntroText(radioElem) {
    if (this.hasVariableIntroTarget) {
      return this.variableIntroTargets.find((t) => t.checked).dataset.defaultText;
    } else {
      return radioElem.dataset.defaultText;
    }
  }

  toggleCKEditor(radioElem, ckEditorFormGroup) {
    const value = radioElem.value;
    const ckId = ckEditorFormGroup.querySelector('textarea').id;
    const ckInstance = this.ckEditorInstances[ckId]
    const helpBlock = ckEditorFormGroup.querySelector('.help-block');

    if (value === 'true') {
      ckInstance.enableReadOnly();
      ckInstance.editor.setData(this.getDefaultIntroText(radioElem));

      // Remove error classes when switching back to default from validation state
      if (ckEditorFormGroup.classList.contains('has-error')) {
        helpBlock.classList.add('hide');
        ckEditorFormGroup.classList.remove('has-error')
      }
    } else {
      ckInstance.disableReadOnly();
    }
  }

  toggleTextInputHandler(e) {
    this.toggleTextInput(e.target);
  }

  toggleTextInput(elem) {
    const value = elem.value;
    const input = document.getElementById(elem.dataset.inputId);

    if (value === 'true') {
      input.disabled = true;
      input.value = elem.dataset.defaultText;

      // reset character counter
      const event = new Event('change');
      input.dispatchEvent(event);
    } else {
      input.disabled = false;
    }
  }

  setCheckboxValues(e) {
    const target = e.target;
    const boxes = Array.from(target.closest('.form-group').querySelectorAll('input[type=checkbox]'));
    const noneBox = boxes.find((b) => b.dataset.isNoneOption === 'true');
    const otherBoxes = boxes.filter((b) => b.dataset.isNoneOption !== 'true');

    if (target.dataset.isNoneOption === 'true' && target.checked) {
      otherBoxes.forEach((b) => b.checked = false);
    } else if (target.checked) {
      noneBox.checked = false;
    }
  }

  toggleConditionalVisibilityHandler(e) {
    this.toggleConditionalVisibility(e.target);
  }

  toggleConditionalVisibilityTwoHandler(e) {
    this.toggleConditionalVisibility(e.target);
  }

  toggleConditionalVisibility(elem, isPageLoad) {
    const value = elem.value;
    const displayTarget = document.getElementById(elem.dataset.displayTarget);

    // Don't hide targets containing CKeditors, we have to wait until those are done initializing before we can hide them
    if (isPageLoad && value !== 'true' && displayTarget.querySelector('.cke-editor') !== null) {
      // Sometimes these targets can be connected before the controller itself, so we would have to initialize the array here
      this.pendingConditionalVisibilityTargets ||= [];
      this.pendingConditionalVisibilityTargets.push(displayTarget);
      return;
    }

    displayTarget.classList.toggle('hide', value !== 'true');
  }

  toggleDefaultPageIntro(e) {
    const ckInstance = this.ckEditorInstances[this.ckEditorWithDefaultTarget.id];
    if (ckInstance.editor.isReadOnly) {
      ckInstance.editor.setData(e.target.dataset.defaultText);
    }
  }

  changePageIntroDefault() {
    // Refresh text if default is selected, otherwise leave custom value alone
    if (this.pageIntroDefaultTarget.checked) {
      this.toggleCKEditor(this.pageIntroDefaultTarget, this.pageIntroCKETarget.closest('.form-group'));
    }
  }

  handleShowCaregiverFields() {
    this.pageIntroDefaultTarget.dataset.defaultText = this.oldIntroDefault;
    this.changePageIntroDefault();
  }

  handleHideCaregiverFields() {
    this.oldIntroDefault = this.pageIntroDefaultTarget.dataset.defaultText;
    this.pageIntroDefaultTarget.dataset.defaultText = this.myselfSelectedDefaultTarget.dataset.defaultText;
    this.changePageIntroDefault();
  }
}
