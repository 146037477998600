import { Controller } from '@hotwired/stimulus';
import { ClassicEditor } from "ckeditor5";
import baseConfig from '../ckeditor/config';

const customToolbars = {
  default: {
    items: ['bold', 'italic', 'underline', '|', 'numberedList', 'bulletedList', '|', 'link'],
    shouldNotGroupWhenFull: false
  },
  stylingOnly: {
    items: ['bold', 'italic', 'underline'],
    shouldNotGroupWhenFull: false
  },
}

export default class extends Controller {
  static values = {
    config: Object,
  };

  connect() {
    this.initConfig();

    // Set custom toolbar if specified in HTML, otherwise use default
    if (this.configValue.toolbar) {
      this.config.toolbar = customToolbars[this.configValue.toolbar];
    } else {
      this.config.toolbar = customToolbars.default;
    }

    // Create max count span below editor if specified
    if (this.config.maxcount) {
      this.createMaxCount();
    }

    this.initializeEditor();
  }

  initConfig() {
    // Merge base config with any values set in the HTML
    // wordCount is a built in plugin to be able to display the current character count
    this.config = {
      ...baseConfig,
      ...this.configValue,
      wordCount: {
        displayWords: false,
        onUpdate: this.updateCharacterCount.bind(this),
      },
    };
  }

  initializeEditor() {
    ClassicEditor.create(this.element, this.config)
      .then(editor => {
        this.editor = editor;
        this.initDisabledOverlay();
        editor.on( 'change:isReadOnly', this.handleReadOnlyChange.bind(this));

        // Set width of max-count span to the current width of the editor
        if (this.config.maxcount) {
          this.repositionMaxCount();
        }

        this.element.classList.remove('hide');
        this.dispatchConnectedEvent();
      })
      .catch(error => {
        console.error("Error initializing CKEditor", error);
      });
  }

  // Creates div that will overlay editor when set to read only
  initDisabledOverlay() {
    this.disabledOverlay = document.createElement('div');
    this.disabledOverlay.classList.add('cke-overlay', 'cke-absolute-position', 'hide');
    this.editor.ui.view.element.appendChild(this.disabledOverlay);
  }

  handleReadOnlyChange(event, propertyName, isReadOnly) {
    this.disabledOverlay.classList.toggle('hide', !isReadOnly);
  }

  createMaxCount() {
    this.maxCountSpan = document.createElement('span');
    this.maxCountSpan.classList.add('max-count');
    this.element.after(this.maxCountSpan);
  }

  repositionMaxCount() {
    this.maxCountSpan.style.width = this.editor.ui.view.element.offsetWidth + 'px';
  }

  updateCharacterCount(stats) {
    if (!this.config.maxcount) return;

    const count = stats.characters;
    const max = this.config.maxcount;
    this.maxCountSpan.textContent = `${count}/${max}`;
    this.maxCountSpan.classList.toggle('over', count > max);
  }

  enableReadOnly() {
    this.editor.enableReadOnlyMode(this.element.id);
  }

  disableReadOnly() {
    this.editor.disableReadOnlyMode(this.element.id);
  }

  // Stimulus controllers can trigger actions based on this event to hook into this CKEditor instance, along with other helpful functions
  dispatchConnectedEvent() {
    const detail = {
      element: this.element,
      editor: this.editor,
      enableReadOnly: this.enableReadOnly.bind(this),
      disableReadOnly: this.disableReadOnly.bind(this),
      repositionMaxCount: this.repositionMaxCount.bind(this),
    }

    this.dispatch('connected', { detail });
  }
}
