import { AutoLink, Autosave, Bold, Essentials, Italic, Link, Paragraph, Underline, List, WordCount } from 'ckeditor5';
import HeightPlugin from "./height_plugin";
import 'ckeditor5/ckeditor5.css';

const LICENSE_KEY = 'GPL';

const editorConfig = {
  plugins: [AutoLink, Autosave, Bold, Essentials, Italic, Link, Paragraph, Underline, List, WordCount, HeightPlugin],
  removePlugins: ["CKEditorCloudServices"],
  licenseKey: LICENSE_KEY,
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
  },
};

export default editorConfig;
