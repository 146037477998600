import { jsonPost } from "./fetch_utils";

// To use this function, ensure the rails action returns JSON in the following format:
// {
//   optionsData: [
//    { text: 'Option 1', value: 'option1' },
//    { text: 'Option 2', value: 'option2' },
//   ]
// }
export function populateSelect(selectElement, ajaxPath, body) {
  jsonPost(ajaxPath, body).then((json) => {
    if (!json.optionsData) return;
    selectElement.innerHTML = '';

    json.optionsData.forEach((optionData) => {
      const option = document.createElement('option');
      option.value = optionData.value;
      option.textContent = optionData.text;
      selectElement.appendChild(option);
    });
  });
}
