import { Controller } from '@hotwired/stimulus';
import { jsonPost, get } from "../helpers/fetch_utils";
import { datepicker } from "../helpers/jquery_wrapper";

export default class extends Controller {
  static values = {
    updateTablePath: String,
    requeuePath: String,
  };

  static targets = [
    'appId',
    'status',
    'startDate',
    'endDate',
    'tableBody',
    'moduleBody',
    'errorModalBody',
  ];

  connect() {
    this.setDatePickerListener();
  }

  updateTable() {
    const body = {
      app_id: this.appIdTarget.value,
      status: this.statusTarget.value,
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value,
    };

    const urlParams = new URLSearchParams(window.location.search);
    const url = `${this.updateTablePathValue}?${urlParams.toString()}`;

    jsonPost(url, body, true).then((html) => {
      const template = document.createElement('template');
      template.innerHTML = html.trim();
      this.moduleBodyTarget.replaceWith(template.content.firstChild);
    });
  }

  setDatePickerListener() {
    datepicker('[data-behaviour~=datepicker]').on("changeDate", this.updateTable.bind(this));
  }

  setPendingResend(e) {
    e.preventDefault();
    this.pendingConnectionId = e.target.dataset.connectionId;
  }

  resendSingleCompletion() {
    this.triggerResend([ this.pendingConnectionId ]);
    this.pendingConnectionId = null;
  }

  resendAllCompletions() {
    const ids = Array.from(this.tableBodyTarget.querySelectorAll('tr')).map((tr) => tr.dataset.connectionId);
    this.triggerResend(ids);
  }

  triggerResend(ids) {
    // Don't do anything with response, user can reload page to check progress
    jsonPost(this.requeuePathValue, { ids }).then(() => {});
  }

  loadErrorModal(e) {
    e.preventDefault();
    get(e.target.dataset.path).then((text) => {

      text.split('\n').forEach((segment) => {
        const p = document.createElement('p');
        p.textContent = segment;
        this.errorModalBodyTarget.appendChild(p);
      });
    });
  }
}
