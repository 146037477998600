import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'clientDropdown',
    'integrationDropdown',
    'hraCheckboxes',
    'hraCheckboxesLeft',
    'hraCheckboxesRight',
    'allCheckbox'
  ];

  connect() {
    this.updateIntegrationDropdown();
  }

  addOption(text, value, applications) {
    const option = document.createElement("option");
    option.textContent = text;
    option.value = value;
    option.setAttribute("data-applications", JSON.stringify(applications));
    this.integrationDropdownTarget.appendChild(option);
  }

  updateIntegrationDropdown() {
    this.integrationDropdownTarget.innerHTML = '';
    const checkedOption = this.clientDropdownTarget.querySelector('option:checked');
    const integrationData = JSON.parse(checkedOption.dataset.integrationData);

    integrationData.forEach((data) => {
      this.addOption(data.text, data.value, data.applications);
    });

    // Select first option
    this.integrationDropdownTarget.querySelector('option').selected = true;

    // HRA options will change too
    this.createHraCheckboxes();
  }

  // Create the checkboxes for the HRAs when the integration is changed
  createHraCheckboxes() {
    this.hraCheckboxesLeftTarget.innerHTML = '';
    this.hraCheckboxesRightTarget.innerHTML = '';

    const checkedOption = this.integrationDropdownTarget.querySelector('option:checked');
    const hraData = JSON.parse(checkedOption.dataset.applications);
    // Split the list into two columns with the extra going on the right if there is an odd number
    const leftHras = hraData.slice(0, Math.floor(hraData.length/2));
    const rightHras = hraData.slice(Math.floor(hraData.length/2));

    leftHras.forEach((data) => {
      this.addCheckbox(data.id, data.name, this.hraCheckboxesLeftTarget);
    });

    rightHras.forEach((data) => {
      this.addCheckbox(data.id, data.name, this.hraCheckboxesRightTarget);
    });

    // Show or hide the "All"
    if (this.allHraCheckboxes().length == 0) {
      this.allCheckboxTarget.closest('.checkbox').classList.add('hide');
    } else {
      this.allCheckboxTarget.closest('.checkbox').classList.remove('hide');
    }

    // Call "All" checkbox changed method to check HRA checkboxes if needed
    this.toggleAllCheckbox();
  }

  // Add an HRA checkbox
  addCheckbox(id, name, parent) {
    const identifier = `application_ids_${id}`;

    const checkbox = document.createElement('div');
    checkbox.classList.add('checkbox')

    const label = document.createElement('label');
    label.for = identifier;

    const input = document.createElement('input');
    input.id = identifier;
    input.type = 'checkbox';
    input.name = 'integrations_resender_form[application_ids][]';
    input.value = id;

    const labelText = document.createTextNode(name);

    label.appendChild(input);
    label.appendChild(labelText);
    checkbox.appendChild(label);

    parent.appendChild(checkbox);
  }

  // If the "All" checkbox is changed, update the HRA checkboxes
  toggleAllCheckbox() {
    this.allHraCheckboxes().forEach((cb) => cb.checked = this.allCheckboxTarget.checked);
  }

  // If any of the HRA checkboxes are changed, update the "All" checkbox
  toggleHraCheckbox() {
    this.allCheckboxTarget.checked = this.allHraCheckboxes().reduce((allChecked, cb) => allChecked && cb.checked, true);
  }

  // Get all the checkboxes since they are split into two columns
  allHraCheckboxes() {
    const left = this.hraCheckboxesLeftTarget.querySelectorAll('input');
    const right = this.hraCheckboxesRightTarget.querySelectorAll('input');

    return [...left, ...right];
  }
}
